import Configuration from "@/services/configuration";
import {
    forgotPassword,
    logout,
    resetPassword,
    resetPasswordAuthentication,
    signin,
    token,
    urlLogin,
    stopImpersonation
} from "../api/auth.api";
import { store } from '@/store/store';
import { cachedHttpClient } from '@/api/httpClient';
import { routes } from "@/router/index.js";

class AuthService {
    login(user) {
        l("in login");

        return signin(user);
    }

    urlLogin(token) {
        l("in urlLogin");

        return urlLogin(token);
    }

    forgotPasswordLink(email) {
        return forgotPassword(email);
    }

    resetPasswordValidity(token) {
        return resetPasswordAuthentication(token);
    }

    updatePassword(request) {
        return resetPassword(request);
    }

    loginToken() {
        l("in loginToken");

        return token().then(this.handleLoginResponse);
    }

    relogin() {
        l("in relogin");

        return this.loginToken();
    }

    logout() {
        l("in logout");

        store.dispatch("setLogoutMode", true); //show loading wheel while logging out
        // Invalid cookie
        localStorage.removeItem('user');
        l("in logout, user removed from localstorage!");
        // clean axios HTTP cache
        cachedHttpClient?.storage?.cleanup();

        return logout()
            .then(response => {
                l('in logout().then:', response);

                return response;
            })
            .catch(error => {
                l('in logout().catch:', error);

                return Promise.reject(error);
            })
            .finally(() => {
                const loginUrl = Configuration.value("loginUrl")
                    ?? routes.find(route => route.name?.toLowerCase() === 'login')?.path
                    ?? '/login';

                l("in logout, navigating to:", loginUrl);

                try {
                    window.jvm.$router
                        .push({ path: loginUrl.replace(/^\/#/, '') })
                        .then(() => window.jvm.$router.go(0));
                } catch (ex) {
                    window.location = loginUrl + '?rl';
                }
            });
    }

    stopImpersonation() {
        stopImpersonation().then(() => {

            // Navigate to /login-success
            window.jvm.$router.push({ path: '/login-success' }).then(() => window.jvm.$router.go(0));
        });
    }

    handleLoginResponse(response) {
        if (response?.data?.accessToken) {
            localStorage.setItem('user', JSON.stringify(response.data));
            jvm.$emit('localStorage:setItem:user', true);
            l('in handleLoginResponse, emitting:', 'localStorage:setItem:user', true);

            return Promise.resolve(response.data);
        }
        return Promise.reject('Access Token not found')
    }

}

export default new AuthService();
